import * as oh from "./ObjectHelper";

export function isNullOrEmpty(value: string): boolean {
    return oh.isNullOrUndefined(value) || value === "";
}

export function isMathOperators(value: string): boolean {
    return value === "+" || value === "-" || value === "*" || value === "/";
}

export function isMathSymbols(value: string): boolean {
    return value === "(" || value === ")" || value === "." || value === ",";
}

export function isMathCharacter(value: string): boolean {
    return isMathOperators(value) || isMathSymbols(value);
}

export function isAlphabetical(value: string): boolean {
    return /^\p{sc=Latn}*$/u.test(value);
}

export function isAlphaNumeric(value: string): boolean {
    return /^[\p{sc=Latn}\p{N}]*$/u.test(value);
}

export function isNumeric(value: string): boolean {
    return /^\p{N}*$/u.test(value);
}

export function isUnderscore(value: string): boolean {
    return value === "_";
}

export function isWhitespace(value: string): boolean {
    return value === " ";
}

export function isOpeningBracket(value: string): boolean {
    return value === "(";
}

export function isClosingBracket(value: string): boolean {
    return value === ")";
}

export function isBetweenCurlyBrackets(value: string, searchValue: string, searchValueStartingIndex: number): boolean {
    let firstPart = value.substring(0, searchValueStartingIndex);
    let leftClosestOpeningCurlyBracketIndex = findAllIndexes(firstPart, "{").pop();
    if (oh.isNullOrUndefined(leftClosestOpeningCurlyBracketIndex) || leftClosestOpeningCurlyBracketIndex < 0) {
        return false;
    }

    let leftClosestClosingCurlyBracketIndex = findAllIndexes(firstPart, "}").pop();
    if (leftClosestClosingCurlyBracketIndex > leftClosestOpeningCurlyBracketIndex) {
        return false;
    }

    let secondPart = value.substring(searchValueStartingIndex + searchValue.length, value.length);
    let rightClosestClosingCurlyBracketIndex = secondPart.indexOf("}");
    if (oh.isNullOrUndefined(rightClosestClosingCurlyBracketIndex) || rightClosestClosingCurlyBracketIndex < 0) {
        return false;
    }

    let rightClosestOpeningCurlyBracketIndex = secondPart.indexOf("{");
    if (oh.isNullOrUndefined(rightClosestOpeningCurlyBracketIndex) || rightClosestOpeningCurlyBracketIndex < 0) {
        return true;
    } else {
        return rightClosestOpeningCurlyBracketIndex > rightClosestClosingCurlyBracketIndex;
    }
}

export function findAllIndexes(value: string, searchValue: string): number[] {
    let indexes: number[] = [];
    let index = value.indexOf(searchValue);
    while (index !== -1) {
        indexes.push(index);
        index = value.indexOf(searchValue, index + 1);
    }

    return indexes;
}

export function replaceFrom(value: string, searchValue: string, replaceValue: string, startingIndex: number): string {
    let firstPart = value.substring(0, startingIndex);
    let secondPart = value.substring(startingIndex + searchValue.length, value.length);
    return firstPart + replaceValue + secondPart;
}

export function removeAll(value: string, searchValue: string): string {
    return value.split(searchValue).join("");
}

export function replaceAll(value: string, searchValue: string, replaceValue: string): string {
    return value.split(searchValue).join(replaceValue);
}

export function getFirstTextBetweenBrackets(value: string, startingIndex: number = 0): string {
    let bracketsCount = 0;
    let text = "";
    for (let i = startingIndex; i < value.length; i++) {
        if (isOpeningBracket(value[i])) {
            bracketsCount++;
        } else if (isClosingBracket(value[i])) {
            bracketsCount--;
            if (bracketsCount === 0) {
                return text;
            }
        } else if (bracketsCount > 0) {
            text += value[i];
        }
    }

    return "";
}

export function insertTextToString(value: string, index: number, text: string): string {
    return value.slice(0, index) + text + value.slice(index);
}

export function removePreviousPartOfTheKeyword(formula: string, startingIndex: number) {
    let nextPart = startingIndex < formula.length ? formula.substring(startingIndex, formula.length) : "";
    for (let i = startingIndex - 1; i >= 0; i--) {
        if (!this.isAlphabetical(formula[i]) && !this.isUnderscore(formula[i])) {
            return formula.substring(0, i + 1) + nextPart;
        }
    }

    return "" + nextPart;
}

export function getPreviousPartOfTheKeyword(formula: string, startingIndex: number): string {
    let keyword = "";
    for (let i = startingIndex; i >= 0; i--) {
        if (this.isAlphabetical(formula[i]) || this.isUnderscore(formula[i])) {
            keyword += formula[i];
        } else {
            break;
        }
    }

    return keyword.split("").reverse().join("");
}

export function findIndexOfChange(originalValue: string, changedValue: string) {
    let index = 0;
    if (this.isNullOrEmpty(originalValue) || this.isNullOrEmpty(changedValue)) {
        return index;
    } else if (originalValue === changedValue) {
        return !oh.isNullOrUndefined(originalValue) ? originalValue.length : 0;
    } else {
        let length = Math.max(originalValue.length, changedValue.length);
        for (let i = 0; i < length; i++) {
            if (originalValue[i] !== changedValue[i]) {
                index = i;
                break;
            }
        }

        return index;
    }
}
