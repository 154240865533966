import {
    AbpMultiTenancyService,
    FeatureCheckerService,
    LocalizationService,
    MessageService,
    NotifyService,
    PermissionCheckerService,
    SettingService,
} from "abp-ng2-module";
import {Component, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppUrlService } from "@shared/common/nav/app-url.service";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { AppUiCustomizationService } from "@shared/common/ui/app-ui-customization.service";
import { PrimengTableHelper } from "shared/helpers/PrimengTableHelper";
import { UiCustomizationSettingsDto } from "@shared/service-proxies/service-proxies";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerTextService } from "@app/shared/ngx-spinner-text.service";
import Handsontable from "handsontable";
import { HotTableRegisterer } from "@handsontable/angular";
import { ProxyTableComponent } from "@shared/common/proxy-table/proxy-table.component";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { InfoModalDto } from "@app/shared/common/info-modal/info-modal.component";
import { NgModel } from '@angular/forms';

interface AbpEventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: '',
})
export abstract class AppComponentBase implements OnInit, OnDestroy {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    eventSubscriptions: AbpEventSubscription[] = [];

    operationDate: moment.Moment;
    startDate: moment.Moment;
    endDate: moment.Moment;

    @ViewChild('proxyTable', { static: true }) localProxyTable: ProxyTableComponent;
    hotTableId: string;
    hotRegisterer = new HotTableRegisterer();
    columns = [];
    hotData: any[];
    defaultOperationDate: moment.Moment;
    startOfMonth: moment.Moment;
    endOfMonth: moment.Moment;
    activatedRoute: ActivatedRoute;

    private ngxSpinnerTextService: NgxSpinnerTextService;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.activatedRoute = injector.get(ActivatedRoute);
    }

    ngOnInit() {
        this.operationDate = this.getOperationDate();
        this.defaultOperationDate = this.getOperationDate();
        this.startDate = moment().utc().startOf('month');
        this.endDate = moment().utc().endOf('month').add('-1', 'days');
        this.startOfMonth = this.startDate.clone();
        this.endOfMonth = this.endDate.clone();
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'app-container container-fluid';
        } else if (this.appSession.theme.baseSettings.layout.layoutType === 'fixed' || this.appSession.theme.baseSettings.layout.layoutType === 'fluid-xxl') {
            return 'app-container container-xxl';
        }

        return 'app-container container';
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    flattenDeep(array) {
        return array.reduce(
            (acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)),
            []
        );
    }

    getHandsontable(id?: string | undefined): Handsontable {
        if (id || this.hotTableId || this.getProxyTableId()) {
            return this.hotRegisterer.getInstance(id || this.hotTableId || this.getProxyTableId());
        }
        return undefined;
    }

    getProxyTableId(): string {
        if (this.localProxyTable) {
            return this.localProxyTable.hotTableId;
        }
    }

    emptyHandsontableSettings(): Handsontable.GridSettings {
        return {
            minSpareCols: 0,
            startRows: 0,
            columns: []
        };
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(_text?: string): void {
        this.spinnerService.hide();
    }

    protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        abp.event.on(eventName, callback);
        this.eventSubscriptions.push({ eventName, callback, });
    }

    private unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }

    /**
     * The idea of adding timezone offset is making sure that
     * operation date is same as local date
     * When we communicate with api nswag generated services
     * will convert the date to utc iso string. Which leading errors during time leaps
     * Such as between 00.00 - 3.00 in UTC + 3
     * Turkey -> 31.10.2024 01.00 -> will give -> 30.10.2024 00.00 utc date
     * But we need 31.10.2024 00.00 operation date in utc format.
     * That is the reason why we append the timeoffset to utc date
     */
    private getOperationDate() {
        const timeOffsetInMinutes = new Date().getTimezoneOffset();

        const { operationDate } = this.activatedRoute.snapshot.queryParams;

        if (this.activatedRoute.snapshot.queryParams.operationDate) {
            return moment(operationDate)
                .add(timeOffsetInMinutes * -1, 'minutes');
        }

        return moment()
            .startOf('day')
            .add(timeOffsetInMinutes * -1, 'minutes')
            .add(1, 'days');
    }

    handleErrorResponse(error: any, isHtml: boolean = true) {
        if (error.response) {
            let response = JSON.parse(error.response);
            if (response.error && response.error.details) {
                this.message.error(response.error.details, response.error.message, { isHtml: isHtml });
            } else {
                this.message.error(response.error.message, null);
            }
        } else {
            this.message.error(error.message, null);
        }
    }

    initializeDeleteModalDto<T>(deleteModalDto: InfoModalDto<T>) {
        deleteModalDto.isLinkTextVisible = false;
        deleteModalDto.isCancelButtonVisible = true;
        deleteModalDto.headerTitleClass = "fs-6 fw-bolder";
        deleteModalDto.headerIconSrc = "/assets/common/icons/trash-dark.svg";
        deleteModalDto.buttonClass = "btn btn-sm btn-danger";
        deleteModalDto.buttonIconSrc = "/assets/common/icons/trash-white.svg";
        deleteModalDto.buttonText = this.l("Delete");
        deleteModalDto.buttonTextClass = "text-white";
    }

    isTouchedAndInvalid(model: NgModel) {
        return model.invalid && (model.dirty || model.touched);
    }
}
