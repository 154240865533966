<link rel="stylesheet" type="text/css" href="sticky-buttons.component.css"/>
<div class="app-engage " id="kt_app_engage">
    <div id="{{sourceInfoElemId}}" *ngIf="sourceInfoElemId">
        <button class="app-engage-btn btn" data-bs-toggle="tooltip" data-bs-placement="left" title="{{ 'SourceInfo' | localize}}">
            <i class="fs-2 flaticon2-gear"></i>
        </button>
    </div>
    <div id="{{operationResultsElemId}}" *ngIf="operationResultsElemId">
        <button class="app-engage-btn btn " data-bs-toggle="tooltip" data-bs-placement="left" title="{{ 'OperationResults' | localize}}">
            <i class="fs-2 fa fa-paper-plane"></i>
        </button>
    </div>
    <div [id]="logsDestinationElemId" *ngIf="logsDestinationElemId">
        <button class="app-engage-btn btn stick-log-button" (click)="onShowLogButtonClick()" id="sticky-log-button">
            <span *ngIf="numberOfLogs" class="log-count">{{ numberOfLogs }}</span>
            <i class="fa-regular fs-2 fa-bell"></i>
        </button>
    </div>
    <div [id]="syncDestinationId" *ngIf="syncDestinationId">
        <button class="app-engage-btn btn  stick-log-button" (click)="onShowLogButtonClick()" id="sticky-sync-button">
            <i class="fs-2 flaticon2-reload"></i>
        </button>
    </div>
</div>
