import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import * as moment from '@node_modules/moment';
import * as oh from '@shared/helpers/ObjectHelper';

@Component({
    selector: 'custom-moment-time-picker',
    templateUrl: './custom-moment-time-picker.component.html',
    styleUrls: ['./custom-moment-time-picker.component.scss'],
    animations: [appModuleAnimation()]
})
export class CustomMomentTimePickerComponent extends AppComponentBase implements OnChanges {

    @Input() id: string;
    @Input() className: string;
    @Input() pickerContainerClassName: string;
    @Input() disabled: boolean = false;
    @Input() required: boolean = false;
    @Input() isNullable: boolean = true;
    @Input() isMeridian: boolean = false;
    @Input() isArrowKeysEnabled: boolean = false;
    @Input() areSpinnersShowed: boolean = true;
    @Input() areSecondsShowed: boolean = false;

    @Input() momentDate?: moment.Moment;
    @Input() momentMinDate?: moment.Moment;
    @Input() momentMaxDate?: moment.Moment;
    @Input() hours?: number;
    @Input() minutes?: number;
    @Input() seconds?: number;

    @Output() momentDateChange = new EventEmitter<moment.Moment>();
    @Output() hoursChange = new EventEmitter<number>();
    @Output() minutesChange = new EventEmitter<number>();
    @Output() secondsChange = new EventEmitter<number>();
    @Output() jsDateChange = new EventEmitter<Date>();
    @Output() timeTextChange = new EventEmitter<string>();

    date: Date;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (this.momentDate) {
            this.date = this.momentDate.toDate();
            this.hours = this.momentDate.hours();
            this.minutes = this.momentDate.minutes();
            this.seconds = this.momentDate.seconds();
        } else {
            if (!this.isNullable) {
                let now = new Date();
                this.hours ??= now.getHours();
                this.minutes ??= now.getMinutes();
                this.seconds ??= now.getSeconds();
                now.setHours(this.hours);
                now.setMinutes(this.minutes);
                now.setSeconds(this.seconds);
                this.date = now;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!oh.isNullOrUndefined(this.momentDate)) {
            if (changes.hours && !changes.hours.isFirstChange()) {
                this.momentDate = this.momentDate.hours(this.hours);
            }

            if (changes.minutes && !changes.minutes.isFirstChange()) {
                this.momentDate = this.momentDate.minutes(this.minutes);
            }

            if (changes.seconds && !changes.seconds.isFirstChange()) {
                this.momentDate = this.momentDate.seconds(this.seconds);
            }

            this.date = this.momentDate.toDate();
        }
    }

    onDateChange(value: Date) {
        this.jsDateChange.emit(value);
        this.momentDate = moment(value);
        this.momentDateChange.emit(this.momentDate);
        this.hours = this.momentDate.hours();
        this.hoursChange.emit(this.hours);
        this.minutes = this.momentDate.minutes();
        this.minutesChange.emit(this.minutes);
        this.seconds = this.momentDate.seconds();
        this.secondsChange.emit(this.seconds);
        this.timeTextChange.emit(this.areSecondsShowed ? this.momentDate.format('HH:mm:ss') : this.momentDate.format('HH:mm'));
    }
}
