import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import * as oh from '@shared/helpers/ObjectHelper';

export enum CustomMomentPickerMode {
    Date,
    DateTime,
    Monthly
}

@Component({
    selector: 'custom-moment-picker',
    templateUrl: './custom-moment-picker.component.html',
    styleUrls: ['./custom-moment-picker.component.scss'],
    animations: [appModuleAnimation()]
})
export class CustomMomentPickerComponent extends AppComponentBase implements OnInit, OnChanges {

    @Input() id: string;
    @Input() label: string;
    @Input() labelIconSrc: string = 'assets/common/icons/info.svg';
    @Input() labelIconTooltipText: string;
    @Input() isLabelIconClickable: boolean = false;
    @Input() onLabelIconClick: EventEmitter<void>;
    @Input() className: string;
    @Input() pickerContainerClassName: string;
    @Input() bsConfig: BsDatepickerConfig;
    @Input() disabled: boolean = false;
    @Input() required: boolean = false;
    @Input() isNullable: boolean = true;

    @Input() momentDate: moment.Moment;
    @Input() momentMinDate: moment.Moment;
    @Input() momentMaxDate: moment.Moment;
    @Input() pickerMode: CustomMomentPickerMode = CustomMomentPickerMode.Date;

    @Output() momentDateChange = new EventEmitter<moment.Moment>();
    @Output() jsDateChange = new EventEmitter<Date>();

    date: Date;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (this.momentDate) {
            this.date = this.momentDate.toDate();
        } else {
            if (!this.isNullable) {
                this.date = new Date();
            }
        }

        switch (this.pickerMode) {
            case CustomMomentPickerMode.Date:
                this.bsConfig = this.bsConfig ?? <BsDatepickerConfig>{ initCurrentTime: false, dateInputFormat: 'yyyy MMM DD', isAnimated: true, adaptivePosition: true };
                break;
            case CustomMomentPickerMode.DateTime:
                this.bsConfig = this.bsConfig ?? { initCurrentTime: false, dateInputFormat: 'YYYY MMM DD HH:mm', withTimepicker: true, keepDatepickerOpened: true, isAnimated: true, adaptivePosition: true } as any;
                break;
            case CustomMomentPickerMode.Monthly:
                this.bsConfig = this.bsConfig ?? <BsDatepickerConfig>{ initCurrentTime: false, dateInputFormat: 'MMMM', minMode: "month", isAnimated: true, adaptivePosition: true };
                break;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.momentDate && !oh.isNullOrUndefined(changes.momentDate.currentValue)) {
            this.date = changes.momentDate.currentValue.toDate();
        } else {
            if (!oh.isNullOrUndefined(this.momentDate)) {
                this.date = this.momentDate.toDate();
            } else {
                if (!this.isNullable) {
                    this.date = new Date();
                } else {
                    this.date = undefined;
                }
            }
        }
    }

    clickLabelIcon() {
        if (!oh.isNullOrUndefined(this.onLabelIconClick)) {
            this.onLabelIconClick.emit();
        }
    }

    onDateChange(value: Date) {
        this.jsDateChange.emit(value);
        this.momentDate = !oh.isNullOrUndefined(value) ? moment(value) : undefined;
        this.momentDateChange.emit(this.momentDate);
    }
}
