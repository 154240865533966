<div
    appBsModal
    #cellLockModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: !saving }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #roleForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">

                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        (click)="close()"
                        [attr.aria-label]="l('Close')"
                        [disabled]="saving"
                    >
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab class="pt-5" heading="{{ this.headerText }}">
                            <div class="mb-5">
                                <label class="form-label">{{ 'Companies' | localize }} *</label>
                                <ng-select id="companies" [items]="companiesForList" bindLabel="name" bindValue="id"
                                           [(ngModel)]="cellLockDto.companyIds" name="companies" [closeOnSelect]="false"
                                           [multiple]="true" (ngModelChange)="toggleSelectAll($event)">
                                </ng-select>
                            </div>
                            <div class="mb-5 row" *ngIf="forLock">
                                <div class="col-12">
                                    <label class="form-check form-check-custom form-check-solid py-1 d-inline-block">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="cellLockDto.userLock"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="cellLockDto.fullSetLock"
                                        />
                                    </label>
                                    <h3 class="margin-left-5 d-inline-block">{{'UserLock' | localize}}</h3>
                                    <hr>
                                </div>
                                <div class="col-12" *ngIf="cellLockDto.userLock && !cellLockDto.fullSetLock">
                                    <moment-bs-datepicker
                                        [Id]="cellLockDto.userLockExpireDate"
                                        [(Date)]="cellLockDto.userLockExpireDate"
                                        [Disabled]="cellLockDto.fullSetLock"
                                        [Clearable]="false"
                                        [LabelToLocalize]="'ExpireDate'">
                                    </moment-bs-datepicker>
                                </div>
                            </div>
                            <div class="mb-5 row" *ngIf="forLock">
                                <div class="col-12">
                                    <label class="form-check form-check-custom form-check-solid py-1 d-inline-block">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="cellLockDto.providerLock"
                                            [ngModelOptions]="{standalone: true}"
                                            [disabled]="cellLockDto.fullSetLock"
                                        />
                                    </label>
                                    <h3 class="margin-left-5 d-inline-block">{{'ProviderLock' | localize}}</h3>
                                    <hr>
                                </div>
                                <div class="col-12" *ngIf="cellLockDto.providerLock && !cellLockDto.fullSetLock">
                                    <moment-bs-datepicker
                                        [Id]="cellLockDto.providerLockExpireDate"
                                        [(Date)]="cellLockDto.providerLockExpireDate"
                                        [Clearable]="false"
                                        [Disabled]="cellLockDto.fullSetLock"
                                        [LabelToLocalize]="'ExpireDate'">
                                    </moment-bs-datepicker>
                                </div>
                            </div>
                            <div class="mb-5 row" *ngIf="forLock">
                                <div class="col-12">
                                    <label class="form-check form-check-custom form-check-solid py-1 d-inline-block">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="cellLockDto.fullSetLock"
                                            [ngModelOptions]="{standalone: true}"
                                        />
                                    </label>
                                    <h3 class="margin-left-5 d-inline-block">{{'FullSetLock' | localize}}</h3>
                                    <hr>
                                </div>
                                <div class="col-12" *ngIf="cellLockDto.fullSetLock">
                                    <moment-bs-datepicker
                                        [Id]="cellLockDto.fullSetLockExpireDate"
                                        [(Date)]="cellLockDto.fullSetLockExpireDate"
                                        [Clearable]="false"
                                        [LabelToLocalize]="'ExpireDate'">
                                    </moment-bs-datepicker>
                                </div>
                            </div>
                        </tab>
                    </tabset>

                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-light-primary fw-bold"
                        (click)="close()"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [disabled]="!roleForm.form.valid"
                        [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')"
                    >
                        <i class="fa {{this.saveButtonIcon}}"></i>
                        <span>{{ this.saveButtonText }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
