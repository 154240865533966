<div [class]="containerClass" [class.invalid]="isTouchedAndInvalid(CustomMomentTimePicker)" class="custom-moment-time-picker-container d-flex justify-content-end px-0">
    <timepicker
        #CustomMomentTimePicker="ngModel"
        [(ngModel)]="date"
        (ngModelChange)="onDateChange($event)"
        [showMeridian]="isMeridian"
        [class]="className"
        [required]="required"
        [disabled]="disabled"
        [min]="momentMinDate?.toDate()"
        [max]="momentMaxDate?.toDate()"
        [arrowkeys]="isArrowKeysEnabled"
        [showSpinners]="areSpinnersShowed"
        [showSeconds]="areSecondsShowed">
    </timepicker>
</div>
