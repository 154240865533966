import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as oh from '@shared/helpers/ObjectHelper';

export interface ICustomSelectItem<T = string | any> {
    label: string;
    value: T;
    iconSrc?: string;
}

@Component({
    selector: 'custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    animations: [appModuleAnimation()]
})
export class CustomSelectComponent extends AppComponentBase implements OnInit {

    @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSelect: EventEmitter<ICustomSelectItem> = new EventEmitter<ICustomSelectItem>();
    @Output() selectedItemChange: EventEmitter<ICustomSelectItem> = new EventEmitter<ICustomSelectItem>();

    @Input() id: string = "";
    @Input() buttonIconSrc: string = "/assets/common/icons/collapse-down-dark.svg";
    @Input() buttonText: string = this.l("Select");
    @Input() buttonClassName: string;

    @Input() items: ICustomSelectItem[] = [];
    @Input() selectedItem: ICustomSelectItem;
    @Input() selectedItems: ICustomSelectItem[] = [];
    @Input() isMultiselect: boolean = false;
    @Input() isButtonIconLeft: boolean = false;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (!this.isMultiselect) {
            if (oh.isNullOrUndefined(this.selectedItem)) {
                if (this.items.length > 0) {
                    this.selectedItem = this.items[0];
                } else {
                    this.selectedItem = undefined;
                }
            }
        } else {
            if (oh.isNullOrUndefined(this.selectedItems)) {
                this.selectedItems = [];
            }
        }
    }

    getButtonText() {
        if (!this.isMultiselect) {
            return !oh.isNullOrUndefined(this.selectedItem) ? this.selectedItem.label : this.buttonText;
        } else {
            return this.selectedItems.length > 0 ?
                (this.selectedItems.length === 1 ? this.selectedItems[0].label : this.selectedItems.length + " " + this.l("Selected")) :
                this.buttonText;
        }
    }

    select(item: ICustomSelectItem) {
        if (!this.isMultiselect) {
            this.selectedItem = item;
            this.close();
            this.selectedItemChange.emit(item);
            this.onSelect.emit(item);
        } else {
            let index = this.selectedItems.findIndex(x => x.value === item.value);
            if (index === -1) {
                this.selectedItems.push(item);
            } else {
                this.selectedItems.splice(index, 1);
            }
            this.selectedItemChange.emit(item);
            this.onSelect.emit(item);
        }
    }

    getItemContainerClass(item: ICustomSelectItem) {
        let isSelected = this.isMultiselect ?
            this.selectedItems.findIndex(x => x.value === item.value) !== -1 :
            this.selectedItem?.value === item.value;
        return isSelected ? "bg-light-primary" : "bg-hover-light";
    }

    getItemLabelClass(item: ICustomSelectItem) {
        let isSelected = this.isMultiselect ?
            this.selectedItems.findIndex(x => x.value === item.value) !== -1 :
            this.selectedItem?.value === item.value;
        return isSelected ? "bg-light-primary text-primary" : "text-hover-inverse-light bg-hover-light";
    }

    open() {
        let containerElement = document.getElementById(this.id + "CustomSelectContainer");
        if (containerElement) {
            containerElement.focus();
            let menuElements = containerElement.getElementsByClassName("custom-select-menu");
            if (menuElements && menuElements.length > 0) {
                for (let i = 0; i < menuElements.length; i++) {
                    let menuElement = menuElements[i] as HTMLDivElement;
                    menuElement.style.display = "block";
                }

                this.onOpen.emit();
            }
        }
    }

    close() {
        let containerElement = document.getElementById(this.id + "CustomSelectContainer");
        if (containerElement) {
            let menuElements = containerElement.getElementsByClassName("custom-select-menu");
            if (menuElements && menuElements.length > 0) {
                (menuElements[0] as HTMLDivElement).style.display = "none";
                this.onClose.emit();
            }
        }
    }

    onFocusout() {
        setTimeout(() => {
            let activeElement = document.activeElement;
            if (activeElement) {
                let containerElement = document.getElementById(this.id + "CustomSelectContainer");
                if (containerElement) {
                    let menuElements = containerElement.getElementsByClassName("custom-select-menu");
                    if (menuElements && menuElements.length > 0) {
                        let menuElement = menuElements[0] as HTMLDivElement;
                        if (!menuElement.contains(activeElement)) {
                            this.close();
                        }
                    }
                }
            }
        }, 100);
    }
}
