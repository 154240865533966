import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
    CompaniesServiceProxy,
    CreatePowerPlantGenerationProvidersDto,
    GetFacilitiesResponseDto,
    Lookup,
    MarketGroupSourceTypesServiceProxy,
    OperationProviderType,
    PowerPlantsServiceProxy
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { PowerPlantUevcbItem } from '@app/shared/common/company-uevcb-list/PowerPlantUevcbItem';
import { CompanyUevcb } from '@app/shared/common/company-uevcb-list/CompanyUevcb';
import {
    DataConditions,
    DataSchema,
    DataTypes,
    IBasicDataSchema,
    IDataSchema,
    TypeOptions
} from '@shared/common/proxy-table/DataSchema';
import Handsontable from 'handsontable';
import {forkJoin, Observable} from 'rxjs';
import { finalize } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-company-uevcb-list',
    templateUrl: './company-uevcb-list.component.html',
    styleUrls: ['./company-uevcb-list.component.css']
})
export class CompanyUevcbListComponent extends AppComponentBase implements OnInit, OnDestroy {
    @ViewChild('uevcbListModal', {static: true}) modal: ModalDirective;
    @ViewChild(TabsetComponent) tabset: TabsetComponent;


    @Output() modalSave: EventEmitter<CompanyUevcb> = new EventEmitter<CompanyUevcb>();
    companyUevcb: CompanyUevcb;
    saving = false;
    tableDataSchemaCol: IDataSchema[] = [];
    tableDataSchemaRow: IBasicDataSchema[] = [];
    settings: Handsontable.GridSettings;
    loading = true;
    facilitiesResponse: GetFacilitiesResponseDto[][] = [];
    facilityNames:string[] =[]
    powerPlantTypes : Lookup[]
    facilityNameAndIds:Map<string,string> = new Map<string, string>();
    constructor(
        private injector: Injector,
        private companiesService: CompaniesServiceProxy,
        private marketGroupSourceTypesServiceProxy: MarketGroupSourceTypesServiceProxy,
        private powerPlantService: PowerPlantsServiceProxy,
    ) {
        super(injector);
    }

    show(etsoCode: string, companyId: number): void {
        this.hotData = [];
        let uevcbRequest = this.companiesService.getUevcbList(etsoCode, companyId);

        let requests: Observable<any>[] = [uevcbRequest];

        if (companyId > 0) {
            let facilitiesRequest = this.marketGroupSourceTypesServiceProxy.getAllForecastProviders(companyId);
            requests.push(facilitiesRequest);
        }

        forkJoin(requests).pipe(finalize(() => this.loading = false))
            .subscribe(results => {
                this.companyUevcb = new CompanyUevcb(results[0].organizationName, results[0].organizationShortName, results[0].organizationCode);
                this.companyUevcb.id = companyId;
                let columns: DataSchema[] = [];

                if (companyId > 0 && results.length > 1 && results[1] != null) {
                    this.facilitiesResponse = results[1];
                    this.facilityNames = results[1].map(x => x.map(y => y.providerName)[0]);
                    results[1].forEach(x => {
                        x.map(y => {
                            this.facilityNameAndIds.set(y.values.facilityName, y.values.facilityId);
                        });
                        let facilitiesSource = new TypeOptions();
                        facilitiesSource.Source = x.map(x => x.values.facilityName);
                        let columnName = x[0].providerName;
                        let facilitiesCol = new DataSchema(this.l(columnName), columnName, DataTypes.Dropdown, false);
                        facilitiesCol.TypeOption = facilitiesSource;
                        columns.push(facilitiesCol);
                    });
                }

                this.hotData = results[0].uevcbList.map(s => new PowerPlantUevcbItem(s.eic, s.name, s.id));
                this.refreshTable(columns);
                this.modal.show();
            });

    }

    ngOnInit() {
        super.ngOnInit();
        this.registerAllPowerPlantTypes()
    }

    setColumns(_: DataSchema[]) {
        this.tableDataSchemaCol = [];
        this.tableDataSchemaCol.push(new DataSchema(this.l('Select'), 'selected', DataTypes.Checkbox, false)
            .setData({
                DisableValidation: true
            }));
        this.tableDataSchemaCol.push(new DataSchema(this.l('Name'), 'name', DataTypes.Text, true));
        this.tableDataSchemaCol.push(new DataSchema(this.l('Eic'), 'eic', DataTypes.Text, true));
        this.tableDataSchemaCol.push(new DataSchema(this.l('UevcbId'), 'uevcbId', DataTypes.Text, true));
        this.tableDataSchemaCol.push(new DataSchema(this.l('InstalledPower'), 'installedPower', DataTypes.Number, false)
            .setData({
                Conditions: [DataConditions.MustNumeric, DataConditions.OnlyPositive, DataConditions.NotEmpty]
            }));
        this.tableDataSchemaCol.push(new DataSchema(this.l('ShortName'), 'shortName', DataTypes.Text, false));


        let typeCol = new DataSchema(this.l('Type'), 'typeName', DataTypes.Dropdown, false);
        let typesSource = new TypeOptions();
        typesSource.Source = this.powerPlantTypes.map(x => x.name);
        typeCol.TypeOption = typesSource;

        this.tableDataSchemaCol.push(typeCol);

    }

    refreshTable(columns?: DataSchema[]) {
        this.setColumns(columns ?? null);
        this.settings = {
            bindRowsWithHeaders: false,

        };
        let handsontable = this.getHandsontable();
        handsontable.updateSettings(this.settings, true);
        handsontable.render();
    }
    private async registerAllPowerPlantTypes() {
        this.powerPlantTypes = [];
        this.powerPlantTypes = await lastValueFrom(this.powerPlantService.getAllPowerPlantTypes());
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }


    save(): void {
        let invalidRows = this.hotData.filter((value: PowerPlantUevcbItem) => value.selected && (!!!value.shortName || value.shortName.length == 0)).length > 0;
        if (invalidRows) {
            this.notify.error(this.l('SelectedPowerPlantsMustHaveShortName'));
            return;
        }
        invalidRows = this.hotData.filter((value: PowerPlantUevcbItem) => value.selected && (!!!value.typeName || value.typeName.length == 0)).length > 0;
        if (invalidRows) {
            this.notify.error(this.l('SelectedPowerPlantsMustHavePowerPlantType'));
            return;
        }
        this.companyUevcb.powerPlants = this.hotData.filter((value: PowerPlantUevcbItem) => value.selected);
        this.companyUevcb.powerPlants.forEach(x=>{
            let fields = Object.keys(x)
            x.type = this.powerPlantTypes.filter(s=>s.name == x.typeName)[0].id;
            const matchingValues = fields.filter(item => this.facilityNames.includes(item));
            if (matchingValues.length >0){
                matchingValues.forEach(z=>{
                    let provider = new CreatePowerPlantGenerationProvidersDto();
                    let statusValue: OperationProviderType = OperationProviderType[z as keyof typeof OperationProviderType];
                    provider.referenceId = this.facilityNameAndIds.get(x[z]);
                    provider.operationProviderId = statusValue;
                    x.providers.push(provider);
                })
            }
        })
        this.modalSave.emit(this.companyUevcb);
        this.modal.hide();
    }

    shouldDisableSaveButton() {
        return false;
    }

    close(): void {

        this.modal.hide();
    }

}
