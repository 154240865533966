<div class="d-flex w-100">
    <div [class]="pickerContainerClassName" class="form-group labeled-date-input-group w-100">
        <label [class.invalid]="isTouchedAndInvalid(CustomMomentPicker)" class="form-label date-input-label m-0" [for]="id">
            <span class="fs-8 px-1">
                {{label ?? l("Date")}}
                <img *ngIf="isLabelIconClickable && !labelIconTooltipText" (click)="clickLabelIcon()" [src]="labelIconSrc" class="label-info-icon cursor-pointer" alt="icons_info"/>
                <img *ngIf="labelIconTooltipText" [custom-tooltip]="labelIconTooltipText" [src]="labelIconSrc" class="label-info-icon cursor-pointer" alt="icons_info"/>
            </span>
        </label>
        <input
            #CustomMomentPicker="ngModel"
            [(ngModel)]="date"
            [bsValue]="date"
            (bsValueChange)="onDateChange($event)"
            [minDate]="momentMinDate?.toDate()"
            [maxDate]="momentMaxDate?.toDate()"
            [disabled]="disabled"
            [required]="required"
            [class]="className"
            [bsConfig]="bsConfig"
            [id]="id"
            class="form-control date-input"
            type="text"
            bsDatepicker />
    </div>
</div>
