import { Directive, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[ngxModalDraggable]'
})
export class NgxModalDraggableDirective implements AfterViewInit {
    private modalElement: HTMLElement;
    private topStart: number;
    private leftStart: number;
    private isDraggable: boolean;
    private handleElement: HTMLElement;

    constructor(public element: ElementRef) {
    }

    ngAfterViewInit() {
        let element = this.element.nativeElement as HTMLElement;

        this.handleElement = this.element.nativeElement;

        this.handleElement.style.cursor = 'pointer';

        while (!element.classList.contains('modal-dialog')) {
            element = element.parentNode as HTMLElement;
        }

        this.modalElement = element;
        this.modalElement.style.position = 'relative';
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (this.handleElement !== this.handleElement) {
            return;
        }

        //enable dragging
        this.isDraggable = true;

        console.log('this.isDraggable', this.isDraggable);

        //store original position
        this.topStart = event.clientY - Number(this.modalElement.style.top.replace('px', ''));
        this.leftStart = event.clientX - Number(this.modalElement.style.left.replace('px', ''));
        event.preventDefault();
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        this.isDraggable = false;
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.isDraggable) {
            //on moving the mouse, reposition the modal
            this.modalElement.style.top = (event.clientY - this.topStart) + 'px';
            this.modalElement.style.left = (event.clientX - this.leftStart) + 'px';
        }
    }

    @HostListener('document:mouseleave', ['$event'])
    onMouseLeave(event: MouseEvent) {
        this.isDraggable = false;
    }

}
