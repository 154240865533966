import { AppConsts } from '@shared/AppConsts';
import { LocalizationService } from 'abp-ng2-module';
import { Injector } from '@angular/core';

export abstract class AppDirectiveBase {

    private readonly localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    private readonly localization: LocalizationService;

    protected constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
    }

    protected l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    private ls(sourceName: string, key: string, ...args: string[]): string {
        let localizedText = this.localization.localize(key, sourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    private flattenDeep(array: string[]) {
        return array.reduce(
            (acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)),
            []
        );
    }
}
