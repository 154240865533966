<div class="stepper-container d-inline-flex">
    <div *ngFor="let step of steps; let i = index" [class]="getStepClass(step)" class="step-container d-flex flex-column">
        <div class="step-icon-container d-flex align-items-center gap-3">
            <div [class]="i - 1 >= 0 ? 'bg-secondary' : ''" class="step-connector w-25px h-1px"></div>
            <div class="step-icon d-flex align-self-center align-items-center justify-content-center h-20px w-20px p-5">
                <span *ngIf="!step.isCompleted && !step.isPassedOptionally" class="fs-4 fw-semibold text-white">{{i + 1}}</span>
                <img *ngIf="step.isCompleted || step.isPassedOptionally" src="assets/common/icons/checked-white.svg" class="icon icon-18px" alt="icons_check"/>
            </div>
            <div [class]="i + 1 <= steps.length - 1 ? 'bg-secondary' : ''" class="step-connector w-25px h-1px"></div>
        </div>
        <div [class]="getStepLabelClass(step)" class="step-label align-self-center">{{step.label}}</div>
    </div>
</div>
